
import { computed, inject, ref, watch } from 'vue';

// icons
import { close, send, refresh, arrowForward, play, sync,
          thumbsUp, thumbsUpOutline, eye, person, chatbubbleEllipsesOutline,
          volumeHigh, volumeMute, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonModal, IonChip,
        IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonTextarea,
        IonGrid, IonCol, IonRow, IonSpinner, IonLabel, IonButtons, IonButton, IonIcon, IonBackButton,
        onIonViewDidEnter, onIonViewWillLeave, loadingController, modalController, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";
import CommentUserChip from '@/components/product/CommentUserChip.vue';
import CommentContent from '@/components/product/CommentContent.vue';
import CommentListModal from '@/components/product/CommentListModal.vue';
import CheckoutModal from '@/components/order/CheckoutModal.vue';
import Slides from "@/components/slides/Slides.vue";
import ProductModal from '@/components/product/ProductModal.vue';

// compsables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { useRoute, useRouter } from 'vue-router';
import config from '@/config';

// services
import ProductService from '@/services/ProductService';

// Supabase
import { SupabaseClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';
import GiftService from '@/services/GiftService';

// Lottie
import lottie from 'lottie-web';

export default {
  name: 'LuckyWheelPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonBackButton, IonFooter, IonModal, IonChip,
                IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList, IonItem, IonTextarea,
                IonGrid, IonCol, IonRow, IonSpinner, IonLabel, IonButtons, IonButton, IonIcon,
                LogoImg, CommentUserChip, CommentContent, Slides, },
  setup() {
    // Supabase
    const supabase: SupabaseClient = inject('supabase');
    const onlineViewerCount = ref(0);
    const onlineViewers = ref([]); // Online users (real-time subscription)

    // methods
    const { presentAlert, openLoginModal, uniqueId, getLocalizedStr, addResizeUrlParams, presentPrompt, openModal,
            presentToast, convertKeysToCamelCase, PRODUCT_STATUSES, } = utils();
    const { t, locale, } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { productId, } = route.params; // Order ID

    const loadingAppPublicData = computed(() => store.state.loadingAppPublicData);
    const settings = computed(() => store.state.settings);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const user = computed(() => store.state.user);
    const checkLoggedIn = () => {
      if (userLoggedIn.value) return true;
      openLoginModal(); // auth guard
      return false;
    }

    // Prize Product Data
    const isCheckoutModalOpened = ref(false);
    const product = computed(() => store.getters.getProductById(productId));
    const latestGamePrice = ref<any>('');
    const refreshingPrizeProducts = ref(true);
    const prizeProducts = ref([]);
    const wonPrizes = ref([]); // store array of won prizes
    const getAvailablePrizes = () => (prizeProducts.value.filter(pp => (pp.status == PRODUCT_STATUSES.available)));
    const updateLocalPrizeProduct = (updatedProductPrize) => {
      const localProductPrize = prizeProducts.value.find(pp => pp.id == updatedProductPrize.id);
      if (localProductPrize) {
        for (const key in updatedProductPrize) {
          localProductPrize[key] = updatedProductPrize[key];
        }
      }
    }

    // Lucky Wheel
    const screenWidth = Math.min(window.innerWidth-10, 400);
    const gridContainer = ref(null);
    const luckyWheelEl = ref(null);
    const isPlayingLuckyWheel = ref(false);
    const wheelBtnText = '刷新';
    const wheelBtns = ref([
      {
        //x: 1, y: 1, col: 3, row: 3,
        x: 2, y: 2,
        //background: config.primaryColor,
        background: '#eb445a',
        fonts: [{ text: wheelBtnText, top: '25%', fontSize: '24px', fontWeight: 600, fontColor: '#fff' }],
      },
    ]);

    // Gift Modal
    const isGiftModalOpened = ref(false);
    const resultGift = ref<any>({});

    // count down auto spin
    const countdownPrizeId = ref(null);
    const currCountdownSec = ref(null);
    const COUNTDOWN_SEC = 10;
    let countdownInterval = null;
    const setWheelBtnText = (val) => {
      wheelBtns.value[0].x = 2;
      wheelBtns.value[0].y = 2;
      wheelBtns.value[0].fonts[0].text = val.toString();
    }
    const resetCountdown = () => {
      currCountdownSec.value = null;
      delete wheelBtns.value[0].x; // hide the center button
      delete wheelBtns.value[0].y; // hide the center button
      if (countdownInterval) clearInterval(countdownInterval);
    }

    const startLuckyDraw = (resultPrizeId = null) => {
      if (!isPlayingLuckyWheel.value) {
        resetCountdown();
        isPlayingLuckyWheel.value = true;
        luckyWheelEl.value.play();
        setTimeout(() => {
          if (resultPrizeId) {
            const resultIdx = prizeProducts.value.findIndex(pp => pp.id == resultPrizeId);
            luckyWheelEl.value.stop(resultIdx);
          } else {
            const prizeProductIds = [];
            for (const pp of prizeProducts.value) {
              const wonPrize = wonPrizes.value.find(wp => wp.id == pp.id);
              if (!wonPrize) { // not include won prizes
                for (let i = 0; i < pp["chance"]; i++) {
                  prizeProductIds.push(pp.id);
                }
              }
            }
            const randomIndex = Math.floor(Math.random() * prizeProductIds.length);
            const resultId = prizeProductIds[randomIndex];
            const resultIdx = prizeProducts.value.findIndex(pp => pp.id == resultId);
            luckyWheelEl.value.stop(resultIdx);
          }
        }, 3000);
      }
    }

    // Supabase Subscribe Game Product (real-time)
    let channel;
    const userStatus = { sessionId: uuidv4(), uid: uuidv4(), name: "Guest User", online_at: new Date().toISOString() };

    const subscribeSupabaseTables = () => {
      if (channel) return; // already subscribed

      setTimeout(() => {
        const { id: productId, } = product.value;
        channel = supabase.channel(productId);

        if (userLoggedIn.value) {
          const { id, name, row } = user.value;
          userStatus.uid = id;
          userStatus.name = name || `User #${row}`;
        }
        // On product updated
        const handleUpdatedProductPrize = (payload) => {
          console.log('Updated product prize received!', payload);
          console.log(convertKeysToCamelCase(payload.new));
          const updatedProductPrize = convertKeysToCamelCase(payload.new);
          updateLocalPrizeProduct(updatedProductPrize);
        }
        // On new / updated product comment
        const handleUpdatedComment = (payload) => {
          console.log('Updated comment received!', payload);
          console.log(convertKeysToCamelCase(payload.new));
          const comment = convertKeysToCamelCase(payload.new);
          if (comment) store.commit('upsertProductComment', { productId, comment });
        }
        // Subscribe product change
        channel
          .on('postgres_changes', { event: '*', schema: 'public', table: 'comments', filter: `product_id=eq.${productId}` }, handleUpdatedComment)
          .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'product_prizes', filter: `product_id=eq.${productId}` }, handleUpdatedProductPrize)
          .on('presence', { event: 'sync' }, () => {
            // Track online viewers & update count
            const newState = channel.presenceState();
            //console.log('sync', newState);
            onlineViewerCount.value = Object.keys(newState).length;
            onlineViewers.value = Object.values(newState).map((s: any) => (s[0] || {}));
          })
          .subscribe(async (status) => {
            if (status !== 'SUBSCRIBED') { return }
            const presenceTrackStatus = await channel.track(userStatus) // send state (join)
            //console.log(presenceTrackStatus)
          })
      }, 1000);
    }

    // Refresh Prize Products (on enter / requests)
    const refreshPrizeProducts = async (showPrompt = false, fullRefresh = true, skipPrizeIds = []) => {
      const callback = () => {
        resetCountdown(); // remove center button;
        prizeProducts.value = []; // empty the board
        refreshingPrizeProducts.value = true;
        luckyWheelEl.value.play(); // play animation to indicate Loading

        GiftService.getLuckyWheelAvailablePrizes(userStatus.sessionId, productId, fullRefresh, skipPrizeIds).then(res => {
          latestGamePrice.value = res.latestLuckyDrawPrice; // update game price
          prizeProducts.value = res.availablePrizes;
          setWheelBtnText(wheelBtnText); // show back the refresh button
          luckyWheelEl.value.stop();
        });
      }
      if (showPrompt) await presentPrompt('', `${t('confirm')}?`, callback);
      else callback();
    }

    // Music (button click / background)
    const clickAudio = ref(new Audio(require('@/assets/sounds/game_btn_click.mp3')));
    const bgAudio = ref(new Audio(require('@/assets/sounds/game_bg.mp3')));
    const isMuted = ref(false);

    const isMobileWeb = () => ('ontouchstart' in document.documentElement);
    const playBtnClickAudio = () => {
      clickAudio.value.volume = 0.9; // High volume (you can adjust this as needed)
      if (clickAudio.value.paused) clickAudio.value.play();
      else clickAudio.value.pause();
    };
    const startBackgroundMusic = () => {
      window.addEventListener(isMobileWeb() ? 'touchstart' : 'click', playBtnClickAudio);
      isMuted.value = false;
      bgAudio.value.volume = 0.3; // Half volume (you can adjust this as needed)
      bgAudio.value.loop = true; // Enable looping
      bgAudio.value.play().catch((error) => {
        // Handle any errors trying to play the audio (e.g., user interaction required)
        console.error('Playback failed', error);
      });
    };
    const stopBackgroundMusic = () => {
      window.removeEventListener(isMobileWeb() ? 'touchstart' : 'click', playBtnClickAudio);
      isMuted.value = true;
      bgAudio.value.pause();
      bgAudio.value.currentTime = 0; // Reset the audio to start for next time
    };

    // Event Cycle (on enter / on leave)
    let animation;
    const fetchProductDetails = (e: any = null) => {
      store.dispatch('fetchProductDetails', { id: productId, e });
    }
    onIonViewDidEnter(() => {
      // Music
      startBackgroundMusic();

      // Init & Fetch Data
      if (product.value.id) {
        refreshPrizeProducts();
        subscribeSupabaseTables();
        if (!product.value.fetchedDetails) fetchProductDetails(); // fetch product details
      }
      
      // Animation
      if (!animation) {
        animation = lottie.loadAnimation({
          container: document.querySelector('#cover-background'), // the DOM element that will contain the animation
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: require('@/assets/lottie/bubble_bg.json'), // the path to your animation file
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: true, // Loads the animation progressively which can help with performance
          },
        });
        animation.setSpeed(0.5); // half speed
      }
    });
    watch(product, (currProduct: any) => {
      subscribeSupabaseTables(); // When directly access the page
      if (!product.value.fetchedDetails) fetchProductDetails(); // fetch product details
      setTimeout(refreshPrizeProducts, 200);
    });
    onIonViewWillLeave(() => {
      stopBackgroundMusic();
      resetCountdown();
      // TBC: delete game session from DB?
    })

    return {
      // icons
      close, send, refresh, arrowForward,
      play, sync, thumbsUp, thumbsUpOutline, eye, person, chatbubbleEllipsesOutline,
      
      // variables
      locale, loadingAppPublicData,
      user, userLoggedIn, onlineViewerCount,
      gridContainer, luckyWheelEl, screenWidth,
      isPlayingLuckyWheel,
      blocks: [
        { padding: '10px', background: '#869cfa' },
        { padding: '10px', background: '#e9e8fe' },
      ],
      wheelBtns,
      refreshingPrizeProducts, prizeProducts, wonPrizes,
      product, latestGamePrice,

      // methods
      t, getLocalizedStr,
      addResizeUrlParams,
      getAvailablePrizes,
      getPrizes: () => {
        const getExtraProps = (idx) => {
          const pp = prizeProducts.value[idx];
          if (!pp) return {};
          const obj = {
            background: '#b8c5f2',
            imgs: [{
              src: addResizeUrlParams(pp.prizeProduct.photoLink, 300),
              //width: '40%',
              height: '70%', top: '15%',
            }],
            prizeProduct: pp,
          }
          if (!isCheckoutModalOpened.value && pp.status != PRODUCT_STATUSES.available && countdownPrizeId.value != pp.id) {
            obj.background = '#000'; // darken the pie (indicate "清盤")
            delete obj.imgs;
          }
          return obj;
        }
        return [
          { x: 0, y: 0, ...getExtraProps(0) },
          { x: 1, y: 0, ...getExtraProps(1) },
          { x: 2, y: 0, ...getExtraProps(2) },
          { x: 3, y: 0, ...getExtraProps(3) },
          { x: 4, y: 0, ...getExtraProps(4) },
          { x: 4, y: 1, ...getExtraProps(5) },
          { x: 4, y: 2, ...getExtraProps(6) },
          { x: 4, y: 3, ...getExtraProps(7) },
          { x: 4, y: 4, ...getExtraProps(8) },
          { x: 3, y: 4, ...getExtraProps(9) },
          { x: 2, y: 4, ...getExtraProps(10) },
          { x: 1, y: 4, ...getExtraProps(11) },
          { x: 0, y: 4, ...getExtraProps(12) },
          { x: 0, y: 3, ...getExtraProps(13) },
          { x: 0, y: 2, ...getExtraProps(14) },
          { x: 0, y: 1, ...getExtraProps(15) },
        ];
      },
      refreshPrizeProducts,

      // Lucky Wheel Animation Callback
      endCallback: (pie) => {
        if (pie && pie.prizeProduct) {
          setTimeout(() => {
            resultGift.value = pie.prizeProduct;
            isPlayingLuckyWheel.value = false;
            countdownPrizeId.value = null;
            wonPrizes.value.push(pie.prizeProduct);
            isGiftModalOpened.value = true;
            setWheelBtnText(wheelBtnText);
          }, 1000);
        }
      },

      // Lucky Draw Game Buttons
      startLuckyDraw,
      startGame: async () => {
        if (checkLoggedIn() && !countdownPrizeId.value) {
          const products = [{ ...product.value, requiredQty: 1, sellingPrice: latestGamePrice.value }];
          //await presentAlert("請先付款")
          setTimeout(() => { isCheckoutModalOpened.value = true }, 500);
          await openModal(CheckoutModal, { products, forceOnlinePayment: true, gameSessionId: userStatus.sessionId, }, true, '', true, 1, false, async({ data }) => {
            isCheckoutModalOpened.value = false;
            const { success, resultPrize, latestPrizes } = data;
            if (success && resultPrize) {
              countdownPrizeId.value = resultPrize.id;
              currCountdownSec.value = COUNTDOWN_SEC;
              setWheelBtnText(COUNTDOWN_SEC);
              countdownInterval = setInterval(() => {
                setWheelBtnText(--currCountdownSec.value);
                if (currCountdownSec.value <= 0) {
                  startLuckyDraw(resultPrize.id);
                  resetCountdown();
                }
              }, 1000);
              for (const x of latestPrizes) {
                updateLocalPrizeProduct({ id: x.id, status: x.prizeProduct.status });
              }
            }
          });
        }
      },
      playAgain: () => {
        startLuckyDraw();
      },
      leaveGame: async () => {
        await presentPrompt("", t("confirmLeave"), async () => {
          router.replace('/');
        });
      },

      // Gift Modal
      isGiftModalOpened,
      resultGift,
      onClickGiftModalOK: () => {
        isGiftModalOpened.value = false;
        refreshPrizeProducts(false, false, [resultGift.value.id]); // "補" prizes
      },

      // Countdown
      countdownPrizeId,

      // Standard Ops
      isShowCommentSection: () => {
        const { showCommentSection, } = settings.value;
        return showCommentSection == 1;
      },
      updateUserLikedItem: async (product: any, action = 'add') => {
        if (checkLoggedIn()) {
          const loading = await loadingController.create({});
          await loading.present();
          if (action == 'add') {
            ProductService.addUserLikedItem(product.id);
            product.numOfLikes++;
          } else {
            ProductService.removeUserLikedItem(product.id);
            product.numOfLikes--;
          }
          product.likedByUser = (action == 'add');
          store.commit("updateUserLikedItem", { product, action });
          loading.dismiss();
          presentToast( t('successUpdateLikedItems'), 1000, 'bottom' );
        }
      },

      // Comments / Live Chat
      openCommentListModal: async () => {
        if (checkLoggedIn()) {
          return await openModal(CommentListModal, { product, onlineViewerCount, onlineViewers }, true, 'comment-list-modal', true, 1, true);
        }
      },

      // Audio
      volumeHigh, volumeMute, isMuted, startBackgroundMusic, stopBackgroundMusic,

      // Grid
      onGridClick: (event) => {// Get the click position
        // Get the click position
        const rect = gridContainer.value.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const gridSize = 5;

        // Calculate the width and height of the border cells
        const cellWidth = screenWidth / gridSize;
        const cellHeight = screenWidth / gridSize;

        // Determine if click is on a border cell
        if (
          (x > cellWidth && x < screenWidth - cellWidth) &&
          (y > cellHeight && y < screenWidth - cellHeight)
        ) {
          // Click is inside the hollow center, not on a border cell
          return;
        }

        // Calculate the index of the grid cell that was clicked
        let index;
        // Top row
        if (y < cellHeight) {
          index = Math.floor(x / cellWidth);
        }
        // Right column
        else if (x >= screenWidth - cellWidth) {
          index = gridSize + Math.floor((y - cellHeight) / cellHeight);
        }
        // Bottom row (minus the rightmost cell)
        if (y > screenWidth - cellHeight && x < screenWidth - cellWidth) {
          index = (gridSize - 1) * 2 + (gridSize - 1 - Math.floor(x / cellWidth));
        }
        // Left column (minus the top and bottom cells)
        if (x < cellWidth && y > cellHeight && y < screenWidth - cellHeight) {
          index = (gridSize - 1) * 3 + (gridSize - 2 - Math.floor((y - cellHeight) / cellHeight));
        }

        // Open Product Modal (enlarge images)
        const prize = prizeProducts.value[index];
        if (prize && prize.prizeProduct) {
          openModal(ProductModal, { id: prize.prizeProduct.id, hidePrice: true });
        }
      },
    }
  },
}
